<template>
  <v-dialog :width="$vuetify.breakpoint.xs ? '100%' : '410px'" v-model="dialog">
    <template v-slot:activator="{ on: onDialog }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: onTooltip }">
          <v-btn
            v-if="mini"
            v-on="{ ...onDialog, ...onTooltip }"
            elevation="0"
            class="ml-2"
            outlined
            fab
            x-small
            @click="open"
            :disabled="disabled"
          >
            <v-icon small>mdi-plus</v-icon>
          </v-btn>
          <v-btn
            outlined
            height="30"
            v-else-if="text && !not_outlined"
            block
            v-on="onDialog"
            @click="open"
            class="mt-1"
          >
            {{
              $t("add", {
                name: $vuetify.breakpoint.xs ? "" : $tc("appointments.session"),
              })
            }}
          </v-btn>

          <v-btn
            height="30"
            v-else-if="text && not_outlined"
            text
            v-on="onDialog"
            @click="open"
            class="mt-1"
          >
            <v-icon class="mr-2" small>$clock</v-icon>
            {{
              $t("add", {
                name: $vuetify.breakpoint.xs ? "" : $tc("appointments.session"),
              })
            }}
          </v-btn>
          <v-btn
            v-else
            v-on="{ ...onDialog, ...onTooltip }"
            icon
            small
            elevation="0"
            @click="open"
            :block="block"
            :disabled="disabled"
          >
            <v-icon small>mdi-plus-circle</v-icon>
          </v-btn>
        </template>
        {{ $t("add", { name: $tc("appointments.session") }) }}
      </v-tooltip>
    </template>
    <v-card id="add">
      <v-card-title>
        {{ $t("add", { name: $tc("appointments.session") }) }}
      </v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <form
        action
        data-vv-scope="add-sesion-form"
        @submit.prevent="validateForm('add-sesion-form')"
      >
        <v-card-text>
          <v-row justify="center">
            <v-col cols="12" class="text-center">
              <v-checkbox
                class="ml-10"
                v-model="newSesion.previos_date"
                label="Poner una fecha anterior"
                hide-details
                off-icon="mdi-circle-outline"
                on-icon="mdi-check-circle"
              ></v-checkbox>
            </v-col>
            <v-date-picker
              first-day-of-week="1"
              v-model="newSesion.date"
              color="primary"
              no-title
              data-vv-validate-on="blur"
              v-validate="'required|min:2'"
              :error-messages="errors.collect('add-sesion-form.date')"
              data-vv-name="date"
              :min="newSesion.previos_date ? null : today"
              :allowed-dates="allowedDates"
              @change="
                fetchBoxes();
                dateError = false;
              "
            ></v-date-picker>
            <v-col cols="5" md="5" class="py-0">
              <label
                v-text="
                  $t(
                    $vuetify.breakpoint.xs
                      ? 'appointments.sessions.time_start_short'
                      : 'appointments.sessions.time_start'
                  )
                "
              />
              <v-autocomplete
                :items="hours"
                v-model="newSesion.time"
                dense
                data-vv-validate-on="blur"
                v-validate="'required|min:2'"
                :error-messages="errors.collect('add-sesion-form.time_start')"
                data-vv-name="time_start"
                hide-details
                outlined
                v-bind:class="{ 'error--text': timeError || horarioError }"
                @change="fetchBoxes"
              ></v-autocomplete>
            </v-col>
            <v-col cols="5" class="py-0">
              <label
                v-text="
                  $t(
                    $vuetify.breakpoint.xs
                      ? 'appointments.sessions.time_end_short'
                      : 'appointments.sessions.time_end'
                  )
                "
              />
              <v-autocomplete
                :items="hours"
                v-model="newSesion.endTime"
                dense
                data-vv-validate-on="blur"
                v-validate="'required|min:2'"
                :error-messages="errors.collect('add-sesion-form.time_end')"
                data-vv-name="time_end"
                hide-details
                outlined
                v-bind:class="{ 'error--text': timeError || horarioError }"
                @change="fetchBoxes"
              ></v-autocomplete>
            </v-col>
            <v-col cols="5" md="5" class="py-0">
              <label v-text="$t('appointments.sessions.price')" />
              <v-text-field
                v-on:keypress="isNumber($event)"
                v-model="newSesion.price"
                dense
                data-vv-validate-on="blur"
                v-validate="''"
                :error-messages="errors.collect('add-sesion-form.price')"
                data-vv-name="price"
                hide-details
                outlined
                suffix="€"
                @input="calculateComision"
              ></v-text-field>
            </v-col>
            <v-col
              cols="5"
              class="py-0"
              v-if="box_active && appointment.tattooer"
            >
              <label v-text="$t('appointments.sessions.box_id')" />
              <v-select
                :items="boxes"
                v-model="newSesion.box_id"
                dense
                data-vv-validate-on="blur"
                v-validate="boxObligatori ? 'required' : ''"
                :error-messages="errors.collect('add-sesion-form.box_id')"
                data-vv-name="box_id"
                hide-details
                outlined
                item-value="id"
                item-text="name"
              >
                <template slot="item" slot-scope="data">
                  <v-icon
                    v-if="
                      !data.item.boxes_tattooer_count ||
                      data.item.boxes_tattooer_count == 0
                    "
                    color="success"
                    class="mr-2"
                  >
                    mdi-check</v-icon
                  >
                  <v-icon v-else color="red" class="mr-2"> mdi-close</v-icon>
                  {{ data.item.name }}
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row justify="center" v-if="hasComisions && appointment.tattooer">
            <v-col cols="5" class="py-0" v-if="$store.getters['auth/isStudio']">
              <label
                class="text-no-wrap"
                v-bind:class="[{ 'pl-0': studioCom }]"
                v-text="
                  `${$t(
                    $vuetify.breakpoint.xs ? 'studio_com_short' : 'studio_com'
                  )} ${studioCom}`
                "
              />
              <v-text-field
                v-on:keypress="isNumber($event)"
                :value="
                  newSesion.price == null
                    ? ''
                    : newSesion.price - newSesion.commision
                "
                dense
                data-vv-validate-on="blur"
                v-validate="''"
                :error-messages="errors.collect('add-sesion-form.price')"
                data-vv-name="price"
                hide-details
                outlined
                disabled
                suffix="€"
              ></v-text-field>
            </v-col>
            <v-col cols="5" class="py-0" v-if="$store.getters['auth/isStudio']">
              <label
                class="text-no-wrap"
                v-bind:class="[{ 'pl-0': tattooerCom }]"
                v-text="
                  `${$t(
                    $vuetify.breakpoint.xs ? 'artist_com_short' : 'artist_com'
                  )} ${tattooerCom}`
                "
              />
              <v-text-field
                v-on:keypress="isNumber($event)"
                v-model="newSesion.commision"
                dense
                data-vv-validate-on="blur"
                v-validate="''"
                :error-messages="errors.collect('add-sesion-form.price')"
                data-vv-name="price"
                hide-details
                outlined
                suffix="€"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="10" class="py-0" v-if="customers.length > 1">
              <label>{{ $tc("customer", 2) }}</label>
              <v-select
                outlined
                dense
                multiple
                :items="customers"
                item-value="id"
                hide-details
                v-model="newSesion.customers"
                :item-text="
                  (x) => `${x.user.first_name} ${x.user.last_name || ''}`
                "
              >
                <template v-slot:selection="{ item, index }">
                  <span v-if="index <= 0">
                    {{ item.user.first_name }} {{ item.user.last_name }}
                  </span>
                  <span v-if="index === 1" class="grey--text text-caption ml-1">
                    (+{{ newSesion.customers.length - 1 }} más)
                  </span>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row
            v-if="horarioError"
            class="py-0"
            style="width: 100%; margin: 0 auto"
          >
            <v-col
              class="login_error white--text pa-1 text-center rounded-xl my-0"
              >Esta fuera del horario establecido</v-col
            >
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-text="$t('cancel')"
            @click="dialog = false"
            outlined
            style="height: 25px"
          />
          <v-btn
            type="submit"
            style="
              width: 100px !important;
              color: #363533;
              height: 25px;
              padding-top: 10px !important;
            "
            elevation="0"
          >
            <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
            {{ $t("save", { name: $t("") }) }}
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapState, mapGetters } from "vuex";
import * as moment from "moment";
export default {
  name: "AddSession",
  props: [
    "appointment",
    "block",
    "mini",
    "disabled",
    "customers",
    "text",
    "not_outlined",
    "block",
  ],
  mounted() {
    this.fetchTimeTables();
    this.today = moment().format("YYYY-MM-DD");
    this.fetchTimeStudio();
    this.fetchBoxes();
    if (this.$store.getters["auth/isStudio"]) {
      this.fetchAll();
    } else {
      this.dates = this.appointment.studio.vacations;
    }
    this.fetchAllVacationsTattooer(
      this.appointment.tattooer.id,
      this.appointment.studio.id
    );
  },
  data() {
    return {
      dialog: false,
      newSesion: { previos_date: false },
      today: null,
      timeStudio: [],
      dates: [],
      datesTattooer: [],
      datesRelation: [],
      studioCom: "",
      tattooerCom: "",
      boxes: [],
    };
  },
  methods: {
    ...mapActions("boxes", ["getBoxes"]),
    fetchBoxes() {
      //console.log("boxes");
      this.getBoxes({
        pagination: null,
        filters: {
          date: this.newSesion.date,
          start_time: this.newSesion.time,
          end_time: this.newSesion.endTime,
          studio_id: this.appointment.studio.id,
        },
      }).then((boxes) => {
        this.boxes = boxes.data;
      });
    },
    calculateComision() {
      if (this.newSesion.price && this.hasComisions) {
        console.log("CALCULAMOS");
        let c = this.appointment.tattooer.tattooer_studios.find(
          (ts) => this.appointment.studio_id === ts.studio_id
        ).comisions;
        console.log(+c.price);
        console.log(c);
        console.log(this.newSesion.price);
        if (c) {
          let comision = c.comisions.find((x) => {
            let from = x.from === "any" || x.from === this.appointment.from; //true
            let cantidad =
              x.price_pos === "any" ||
              (x.price_pos === "lower" && +this.newSesion.price <= +c.price) ||
              (x.price_pos === "bigger" && +this.newSesion.price > +c.price);
            return from && cantidad;
          });
          console.log(comision);
          if (comision.type === "money") {
            this.newSesion.commision = comision.valor;
          } else {
            this.newSesion.commision =
              (this.newSesion.price * +comision.valor) / 100;
          }

          if (comision) {
            this.tattooerCom = `${comision.valor} ${
              comision.type === "money" ? "€" : "%"
            }`;
            let a;
            if (comision.type === "money") {
              a = this.newSesion.price - comision.valor;
            } else {
              a = 100 - comision.valor;
            }
            this.studioCom = `${a} ${comision.type === "money" ? "€" : "%"}`;
          }
        }
      }
    },
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9.]+$/.test(char)) return true;
      else e.preventDefault();
    },
    ...mapActions("appointments", ["addSesion"]),
    async validateForm(scope) {
      this.errors.clear();
      //console.log("validando");
      if (!this.newSesion) {
        this.dateError = true;
      } else {
        this.dateError = false;
      }
      this.$validator.validateAll(scope).then((result) => {
        if (result && !this.horarioError && !this.timeError) {
          this.addSesion({
            appointment_id: this.appointment.id,
            formData: {
              ...this.newSesion,
              timezone: -new Date().getTimezoneOffset(),
            },
          }).then((data) => {
            if (data.success) {
              this.dialog = false;
              this.newSesion = {};
              this.$alert(this.$t("save_ok"));
            } else {
              switch (data.type) {
                case "box":
                  this.$alert(this.$t("event_save_cant"));
                  break;
                case "artist":
                default:
                  this.$alert(this.$t("session_overlap"));
                  break;
              }
            }
            this.$emit("update");
          });
        }
      });
    },

    ...mapActions("studios", ["getStudioTattooersByDate", "getStudio"]),
    ...mapActions("tattooers", ["getTattooer"]),
    fetchTimeStudio() {
      let studio_id = null;
      if (this.$store.getters["auth/isStudio"]) {
        this.getStudio(this.$store.state.auth.user.studio.id).then((data) => {
          ////console.log(data);
          this.timeStudio = data.timetable;
          ////console.log(this.timeStudio);
        });
        this.getTattooer(this.appointment.tattooer.id).then((data) => {
          console.log(data);
          if (data.studios.length) {
            let y = data.studios.find(
              (x) => x.id === this.$store.state.auth.user.studio.id
            );
            this.datesRelation[0] = y.pivot.from;
            this.datesRelation[1] = y.pivot.to;
          }
        });
      } else if (this.$store.getters["auth/isTattooer"]) {
        ////console.log(this.$store.state.auth.user);
        this.getTattooer(this.$store.state.auth.user.tattooer.id).then(
          (data) => {
            console.log(data);

            if (data.tattooer_studios.length) {
              this.timeStudio = data.tattooer_studios.find(
                (ts) => this.appointment.studio_id === ts.studio_id
              ).studio.timetable;
            }

            if (data.studios.length) {
              let y = data.studios.find(
                (x) => x.id === this.appointment.studio.id
              );
              this.datesRelation[0] = y.pivot.from;
              this.datesRelation[1] = y.pivot.to;
            }
          }
        );
      }
    },
    open() {
      this.newSesion = { date: this.today };

      this.newSesion.customers = this.customers.map((c) => c.id);
    },
    ...mapActions("close_days", ["getAll"]),
    fetchAll() {
      this.getAll().then((data) => {
        if (data) {
          this.dates = data;
          console.log(this.dates);
        }
      });
    },
    ...mapActions("close_days_tattooer", ["getAllTattooer"]),
    fetchAllVacationsTattooer(tattooer, studio) {
      console.log(tattooer);
      this.getAllTattooer({ tattooer_id: tattooer, studio_id: studio }).then(
        (data) => {
          if (data) {
            this.datesTattooer = data;
            console.log(this.datesTattooer);
          }
        }
      );
    },
    ...mapActions("timetables", ["getTimetables", "getTimetableResume"]),
    fetchTimeTables() {
      this.getTimetableResume({
        studio_id:
          this.appointment.studio_id || this.$store.auth.user.studio.id,
      });
      this.getTimetables({
        filters: {
          studio_id:
            this.appointment.studio_id || this.$store.auth.user.studio.id,
        },
      });
    },
    ...mapGetters("timetables", ["getDayTimetable"]),
    allowedDates(date) {
      let a = this.dates.find((x) => {
        return x.start_date <= date && x.end_date >= date;
      });

      if (a) {
        console.log(a);
        return false;
      }
      let t = this.datesTattooer.find((x) => {
        return x.start_date <= date && x.end_date >= date;
      });

      if (t) {
        //console.log(t);
        return false;
      }
      let h = this.getDayTimetable()(
        date,
        this.appointment.studio_id,
        this.appointment.tattooer_id
      );
      if (h) {
        return h.length > 0;
      }
      return false;
    },
    dayOfWeek(date) {
      let d = new Date(date);

      d = d.getDay() - 1;
      if (d < 0) d = 6;
      return d;
    },
  },
  computed: {
    box_active() {
      let s = this.appointment.studio.user.setting.find(
        (x) => x.key === "box_active"
      );
      if (s) return s.value === "1" && this.appointment.studio.user.plan.boxes;
      return false;
    },
    boxObligatori() {
      let s = this.appointment.studio.user.setting.find(
        (x) => x.key === "boxes_obligatoris"
      );
      if (s) return s.value === "1" && this.box_active;
      return false;
    },
    timeTable() {
      if (this.appointment.tattooer) {
        let dh = this.appointment.tattooer.tattooer_studios.find((x) => {
          return x.studio_id === this.appointment.studio_id;
        });
        console.log("dh", dh);
        if (dh && dh.tattooer_timetable) return dh.tattooer_timetable;
      }
      return this.timeStudio;
    },
    hasComisions() {
      let s = this.appointment.studio.user.setting.find(
        (x) => x.key === "comisions_active"
      );
      if (s) return s.value === "1";
      return true;
    },
    hours() {
      let mins = [":00", ":15", ":30", ":45"];
      let hours = [];
      let first = parseInt(this.timeFrom.split(":")[0]);
      let last = parseInt(this.timeTo.split(":")[0]);
      for (let x = first; x < last; x++) {
        mins.forEach((y) => {
          if (x < 10) hours.push("0" + x + y);
          else hours.push(x + y);
        });
      }

      hours.push(last + ":00");
      if (this.timeTo.split(":")[1] == "30") hours.push(last + ":30");
      return hours;
    },
    timeFrom() {
      if (this.timeStudio.length > 0) {
        let min = "23:00";
        let minFinal = null;

        this.timeStudio.forEach((dia) => {
          dia.forEach((hora_overtura) => {
            let h = hora_overtura[0];
            if (h <= min) {
              min = h;
            }
          });
        });
        return min;
      }
      return "00:00";
    },
    timeTo() {
      if (this.timeStudio.length > 0) {
        let max = "00:00";
        let maxFinal = null;

        this.timeStudio.forEach((dia) => {
          dia.forEach((hora_overtura) => {
            let h = hora_overtura[1];
            if (h >= max) {
              max = h;
            }
          });
        });
        return max;
      }
      return "24:00";
    },
    horarioError() {
      if (
        this.newSesion.date &&
        this.newSesion.time &&
        this.newSesion.endTime
      ) {
        let dh = this.timeTable[this.dayOfWeek(this.newSesion.date)];
        let error = true;
        dh.forEach((h) => {
          if (h[0] <= this.newSesion.time && h[1] >= this.newSesion.endTime)
            error = false;
        });
        return error;
      }
      return false;
    },
    timeError() {
      if (
        this.newSesion.time !== undefined &&
        this.newSesion.endTime !== undefined
      ) {
        let s = this.newSesion.time.split(":");
        let e = this.newSesion.endTime.split(":");
        if (s[0] < e[0]) {
          return false;
        } else if (s[0] > e[0]) {
          return true;
        }

        if (s[1] <= e[1]) {
          return false;
        }
        return true;
      }
      return false;
    },
  },
};
</script>
<style scoped lang="sass">
#add

label
  display: block
  height: 25px
</style>
